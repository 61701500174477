import { Box, SystemStyleObject } from "theme-ui";
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";

import React from "react";

interface Props extends FontAwesomeIconProps {
  icon: any;
  sx?: SystemStyleObject;
}

const Icon: React.FC<Props> = ({ icon, sx, ...rest }) => (
  <Box
    sx={{
      display: "inline-flex",
      ...sx,
    }}
  >
    <FontAwesomeIcon icon={["fal", icon]} {...rest} />
  </Box>
);

export default Icon;

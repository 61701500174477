import { Box, Button, Card, Flex, Heading } from "theme-ui";
import { ButtonLink, FlexSpinner, Icon } from "../../components";
import { useMutation, useQuery } from "@apollo/react-hooks";

import ListItem from "../../scales/feelings/list-item";
import React from "react";
import format from "date-fns/format";
import { gql } from "apollo-boost";
import { navigate } from "hookrouter";
import parseISO from "date-fns/parseISO";
import { useLoginRequired } from "@commongooddev/commoncode/hooks/use-login-required";

const GroupQuery = gql`
  query GroupQuery($id: uuid!) {
    groups_by_pk(id: $id) {
      id
      name
      group_moods_as_group_user {
        id
        scale
        mood_at
        feelings {
          id
          feeling
          strength
        }
        user {
          publicName
        }
      }
    }
  }
`;

const DeleteGroupMutation = gql`
  mutation DeleteGroupMutation($id: uuid!) {
    delete_groups_by_pk(id: $id) {
      id
    }
  }
`;

interface Props {
  groupId: string;
}

const Group: React.FC<Props> = ({ groupId }) => {
  useLoginRequired();

  const { data, loading, error } = useQuery(GroupQuery, {
    variables: { id: groupId },
    fetchPolicy: "cache-and-network",
  });
  const [deleteGroup] = useMutation(DeleteGroupMutation, {
    variables: { id: groupId },
    refetchQueries: ["GroupsQuery"],
    awaitRefetchQueries: true,
  });

  const handleDeleteGroup = async () => {
    await deleteGroup();
    navigate("/groups");
  };

  if (loading) return <FlexSpinner />;
  if (error) return <Box>{error.message}</Box>;

  const { groups_by_pk: group } = data;
  const { group_moods_as_group_user: moods } = group;

  return (
    <Box>
      <Heading sx={{ mb: 3 }}>{group.name || "Group"}</Heading>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: [
            "1fr",
            "repeat(2, 1fr)",
            "repeat(3, 1fr)",
            "repeat(4, 1fr)",
          ],
          gap: 3,
        }}
      >
        {moods.map((mood: any) => (
          <Card key={mood.id} sx={{ p: 2, cursor: "default" }}>
            <Flex
              sx={{
                justifyContent: "space-between",
                alignItems: "stretch",
                backgroundColor: "primary",
                color: "white",
                fontSize: 0,
                whiteSpace: "nowrap",
                borderRadius: "4px 4px 0 0",
                p: 1,
                mt: -2,
                ml: -2,
                mr: -2,
                mb: 3,
              }}
            >
              <Box
                sx={{
                  display: "inline-flex",
                  alignItems: "center",
                  px: 1,
                  "> a": { textDecoration: "none", color: "white" },
                }}
              >
                {mood.user.publicName}
              </Box>
              <Box
                sx={{
                  display: "inline-flex",
                  alignItems: "center",
                  px: 1,
                }}
              >
                {format(parseISO(mood.mood_at), "PP 'at' p")}
              </Box>
            </Flex>
            <ListItem mood={mood} />
          </Card>
        ))}
      </Box>
      <Flex sx={{ justifyContent: "space-between", mt: 5 }}>
        <ButtonLink href="/groups">
          <Icon icon="arrow-left" sx={{ mr: 2 }} />
          Back
        </ButtonLink>
        <Button
          onClick={handleDeleteGroup}
          variant="secondary"
          sx={{ "&:hover": { backgroundColor: "danger" } }}
        >
          <Icon icon="trash" sx={{ mr: 2 }} />
          Delete
        </Button>
      </Flex>
    </Box>
  );
};

export default Group;

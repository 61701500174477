import { Box, Text } from "theme-ui";
import React, { useState } from "react";

import isEmpty from "lodash/isEmpty";
import sortBy from "lodash/sortBy";
import useFeelings from "./use-feelings";

interface Props {
  mood: any;
}

const ListItem: React.FC<Props> = ({ mood }) => {
  const { lookupByName } = useFeelings();
  const [currentFeeling, setCurrentFeeling] = useState<any>(null);

  if (isEmpty(mood.feelings)) {
    return (
      <Box sx={{ textAlign: "center", color: "#999", fontSize: 1, pb: 2 }}>
        This is a blank mood.
        <Text sx={{ textDecoration: "underline" }}>Add something!</Text>
      </Box>
    );
  }

  const sortedFeelings = sortBy(
    mood.feelings,
    ({ strength }: any) => strength || 1
  ).reverse();

  return (
    <>
      <Box
        sx={{
          height: "2rem",
          display: "grid",
          gridTemplateColumns: sortedFeelings
            .map(({ strength }: any) => `${strength || 1}fr`)
            .join(" "),
          gap: "2px",
          mb: 2,
          "div:first-of-type": {
            borderTopLeftRadius: 4,
            borderBottomLeftRadius: 4,
          },
          "div:last-of-type": {
            borderTopRightRadius: 4,
            borderBottomRightRadius: 4,
          },
        }}
      >
        {sortedFeelings.map(({ id, feeling }: any) => {
          const { color } = lookupByName(feeling);
          return (
            <Box
              key={`chart${id}`}
              sx={{
                height: "2rem",
                backgroundColor: color,
                userSelect: "none",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: currentFeeling === feeling ? "white" : "transparent",
              }}
              onMouseOver={() => setCurrentFeeling(feeling)}
              onMouseOut={() => setCurrentFeeling(null)}
            >
              <span style={{ opacity: 0.5 }}>•</span>
            </Box>
          );
        })}
      </Box>
      <Box sx={{ fontSize: 0 }}>
        {sortedFeelings.map(({ feeling }, index) => (
          <span
            key={feeling}
            style={{ color: "#aaa" }}
            onMouseOver={() => setCurrentFeeling(feeling)}
            onMouseOut={() => setCurrentFeeling(null)}
          >
            {index > 0 && ", "}
            {feeling === currentFeeling ? (
              <span
                style={{
                  color: "black",
                  borderBottom: `1px solid ${
                    lookupByName(currentFeeling).color
                  }`,
                }}
              >
                {feeling}
              </span>
            ) : (
              <span>{feeling}</span>
            )}
          </span>
        ))}
      </Box>
    </>
  );
};

export default ListItem;

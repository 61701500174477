import {
  Button,
  SystemStyleObject,
  Link as ThemeLink,
  LinkProps as ThemeLinkProps,
} from "theme-ui";
import { HookRouter, setLinkProps } from "hookrouter";

import React from "react";

interface LinkProps extends ThemeLinkProps {
  href: string;
  sx?: SystemStyleObject;
}

const Link: React.FC<LinkProps> = ({ sx, ...rest }) => (
  <ThemeLink {...setLinkProps(rest as HookRouter.AProps)} sx={sx} />
);

const MinimalLink: React.FC<LinkProps> = ({ sx, ...rest }) => (
  <Link sx={{ textDecoration: "none", color: "inherit", ...sx }} {...rest} />
);

const ButtonLink: React.FC<LinkProps> = ({ sx, ...rest }) => (
  <Button
    as="a"
    {...(setLinkProps(rest as HookRouter.AProps) as any)}
    sx={{
      display: "inline-flex",
      justifyContent: "center",
      alignItems: "center",
      ...sx,
    }}
  />
);

export { Link, MinimalLink, ButtonLink };

import "./icons";
import "./index.css";

import * as serviceWorker from "./serviceWorker";

import App from "./app";
import { CommonCodeProvider } from "@commongooddev/commoncode/hooks/use-common-code";
import { IntercomProvider } from "react-use-intercom";
import React from "react";
import ReactDOM from "react-dom";

ReactDOM.render(
  <IntercomProvider
    appId={process.env.REACT_APP_INTERCOM_APP_ID || "voyev7zk"}
    autoBoot={true}
  >
    <CommonCodeProvider helloUrl={process.env.REACT_APP_HELLO_URL}>
      <App />
    </CommonCodeProvider>
  </IntercomProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

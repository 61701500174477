import {
  faArrowLeft,
  faCheckCircle,
  faClock,
  faFileSignature,
  faLineColumns,
  faMinus,
  faNetworkWired,
  faPlus,
  faPollPeople,
  faShare,
  faShareAll,
  faSpinner,
  faTasksAlt,
  faTimes,
  faTimesCircle,
  faTrash,
  faUserFriends,
  faUsersClass,
} from "@fortawesome/pro-light-svg-icons";

import { library } from "@fortawesome/fontawesome-svg-core";

library.add(
  faArrowLeft,
  faCheckCircle,
  faClock,
  faFileSignature,
  faLineColumns,
  faMinus,
  faNetworkWired,
  faPlus,
  faPollPeople,
  faShare,
  faShareAll,
  faSpinner,
  faTasksAlt,
  faTimes,
  faTimesCircle,
  faTrash,
  faUserFriends,
  faUsersClass
);

import { Flex, Spinner, SpinnerProps } from "theme-ui";

import React from "react";

const FlexSpinner: React.FC<SpinnerProps> = props => (
  <Flex
    sx={{
      flex: 1,
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Spinner {...(props as any)} />
  </Flex>
);

export { FlexSpinner };

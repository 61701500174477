import { Box, Input, Text, Textarea } from "theme-ui";
import React, { useState } from "react";

import Feeling from "./feeling";
import { Helmet } from "react-helmet";
import Rating from "react-rating";
import debounce from "lodash/debounce";
import isEmpty from "lodash/isEmpty";
import some from "lodash/some";
import useFeelings from "./use-feelings";
import { useIntercom } from "react-use-intercom";

type Feeling = { name: string; color?: string; children?: Feeling[] };

const Reflect: React.FC<any> = ({ mood, updateMood, updateFeeling }) => {
  const { lookupByName } = useFeelings();

  const { trackEvent } = useIntercom();

  const [hasReflected, setHasReflected] = useState(
    mood.thoughts || some(mood.feelings, ({ thoughts }) => !!thoughts)
  );
  const conditionallyTrackReflect = () => {
    if (!hasReflected) {
      trackEvent("Reflected on Mood");
      setHasReflected(true);
    }
  };

  const [hasRanked, setHasRanked] = useState(
    some(mood.feelings, ({ strength }) => !!strength)
  );
  const conditionallyTrackRank = () => {
    if (!hasRanked) {
      trackEvent("Ranked Feelings");
      setHasRanked(true);
    }
  };

  const handleUpdateStrength = async (feeling: any, strength: number) => {
    await updateFeeling({
      variables: { ...feeling, strength },
      optimisticResponse: {
        update_feelings_by_pk: {
          __typename: "feelings",
          ...feeling,
          mood_id: mood.id,
          strength,
        },
      },
    });
    conditionallyTrackRank();
  };

  const handleUpdateThoughts = async (feeling: any, thoughts: string) => {
    await updateFeeling({ variables: { ...feeling, thoughts } });
    conditionallyTrackReflect();
  };

  const debouncedHandleUpdateThoughts = debounce(handleUpdateThoughts, 1000);

  const handleUpdateMoodThoughts = async (thoughts: string) => {
    await updateMood({ variables: { thoughts, mood_at: mood.mood_at } });
    conditionallyTrackReflect();
  };

  const debouncedHandleUpdateMoodThoughts = debounce(
    handleUpdateMoodThoughts,
    1000
  );

  const Meter = ({ color }: { color: string }) => (
    <Box
      sx={{
        width: "0.75rem",
        height: "2rem",
        backgroundColor: color,
        mx: 1,
        borderRadius: 2,
      }}
    />
  );

  return (
    <Box sx={{ flex: 1 }}>
      <Helmet>
        <title>Reflect</title>
      </Helmet>
      <Text sx={{ mb: 3 }}>
        Take some time to write down your thoughts; reflect on your current
        mood.
      </Text>
      <Textarea
        sx={{ mb: 3, width: "100%" }}
        rows={6}
        placeholder="Right now, my current mood is..."
        defaultValue={mood.thoughts || ""}
        onChange={e => debouncedHandleUpdateMoodThoughts(e.currentTarget.value)}
        onBlur={e => handleUpdateMoodThoughts(e.currentTarget.value)}
      />
      <Text sx={{ my: 3, mt: 4 }}>
        {isEmpty(mood.feelings)
          ? "You haven't added any feelings in either identify or explore. Go back and try adding a couple feelings to get started."
          : "Now you're able to select the strength of each feeling below from 1-5 and capture some thoughts on each feeling."}
      </Text>
      {!isEmpty(mood.feelings) && (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: ["1fr", "max-content 1fr"],
            gap: 2,
            flex: 1,
            mb: 3,
          }}
        >
          {mood.feelings.map(({ __typename, ...feeling }: any) => {
            const { color } = lookupByName(feeling.feeling);
            return (
              <React.Fragment key={feeling.id}>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr max-content",
                    gap: 2,
                  }}
                >
                  <Feeling
                    isSelected={true}
                    animate={false}
                    baseColor={color}
                    onClick={() => null}
                    sx={{
                      height: "2rem",
                      minWidth: "122px",
                      m: 0,
                      cursor: "default",
                    }}
                  >
                    {feeling.feeling}
                  </Feeling>
                  <Box sx={{ height: "2rem" }}>
                    <Rating
                      initialRating={feeling.strength}
                      emptySymbol={<Meter color="#eee" />}
                      fullSymbol={[
                        <Meter color="#B8EA85" data-rating="1" />,
                        <Meter color="#99E151" data-rating="2" />,
                        <Meter color="#73C421" data-rating="3" />,
                        <Meter color="#63BA34" data-rating="4" />,
                        <Meter color="#4AA11B" data-rating="5" />,
                      ]}
                      onChange={(newStrength: number) =>
                        handleUpdateStrength(feeling, newStrength)
                      }
                    />
                  </Box>
                </Box>
                <Input
                  sx={{
                    flex: 1,
                    height: ["auto", "2rem"],
                    p: 1,
                    mb: [3, null],
                  }}
                  placeholder={`I feel ${feeling.feeling.toLowerCase()} because...`}
                  defaultValue={feeling.thoughts || ""}
                  onChange={e =>
                    debouncedHandleUpdateThoughts(
                      feeling,
                      e.currentTarget.value
                    )
                  }
                  onBlur={e =>
                    handleUpdateThoughts(feeling, e.currentTarget.value)
                  }
                />
              </React.Fragment>
            );
          })}
        </Box>
      )}
    </Box>
  );
};

export default Reflect;

import { Box, Button, Card, Divider, Flex, Text } from "theme-ui";
import React, { useState } from "react";

import Feeling from "./feeling";
import { Helmet } from "react-helmet";
import { Icon } from "../../components";
import find from "lodash/find";
import isEmpty from "lodash/isEmpty";
import slice from "lodash/slice";
import useFeelings from "./use-feelings";
import { useIntercom } from "react-use-intercom";

type Index = number | null;

type Feeling = { name: string; color?: string; children?: Feeling[] };

const Identify: React.FC<any> = ({ mood, insertFeeling, deleteFeeling }) => {
  const { lookupByName, hierarchy } = useFeelings();

  const [showAdd, setShowAdd] = useState(false);

  const [selectedIndexes, setSelectedIndexes] = useState<[Index, Index, Index]>(
    [null, null, null]
  );

  const { trackEvent } = useIntercom();

  const handleIdentifyClick = (level: number, index: number) => {
    setSelectedIndexes(prev => {
      const start = slice(prev, 0, level);
      const flip = prev[level] === index ? null : index;
      const end = Object.assign(Array(3).fill(null), [...start, flip]) as [
        Index,
        Index,
        Index
      ];
      return end;
    });
  };

  const handleAddFeeling = async (feeling: any) => {
    setShowAdd(false);
    setSelectedIndexes([null, null, null]);
    await insertFeeling({
      variables: {
        mood_id: mood.id,
        feeling: feeling.name,
      },
      optimisticResponse: {
        insert_feelings_one: {
          __typename: "feelings",
          id: null,
          strength: null,
          thoughts: null,
          mood_id: mood.id,
          feeling: feeling.name,
        },
      },
    });
    trackEvent("Added Feeling", { From: "Identify" });
  };

  const identifiedFeelings =
    mood?.feelings?.map(({ feeling }: any) => feeling) || [];

  const identifyFeelings = (
    feelingsList: Feeling[],
    level: number = 0,
    baseColor?: string
  ): any => {
    const selectedIndex = selectedIndexes[level];

    let children;
    if (selectedIndex !== null && feelingsList[selectedIndex]?.children) {
      children = identifyFeelings(
        feelingsList[selectedIndex!].children!,
        level + 1,
        feelingsList[selectedIndex!]?.color || baseColor
      );
    }
    return [
      <React.Fragment key={level}>
        {feelingsList.map(({ name, color }, i) => {
          const isSelected = selectedIndex === i;
          const notSelected = selectedIndex !== null && !isSelected;
          const isIdentified = !!find(
            identifiedFeelings,
            feeling => feeling === name
          );
          if (notSelected) return null;
          return (
            <Flex
              key={`ident-${name}`}
              sx={{
                alignItems: "center",
                width: isSelected ? ["100%", "auto"] : undefined,
              }}
            >
              <Feeling
                isSelected={isSelected}
                baseColor={notSelected ? "#ccc" : color || baseColor}
                onClick={() => handleIdentifyClick(level, i)}
                sx={{
                  flexGrow: 0,
                  height: "2rem",
                  borderTopRightRadius: isSelected ? 0 : undefined,
                  borderBottomRightRadius: isSelected ? 0 : undefined,
                }}
              >
                {name}
              </Feeling>
              {isSelected && (
                <Box
                  sx={{
                    height: "2rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    color: color || baseColor,
                    border: "1px solid black",
                    borderColor: color || baseColor,
                    borderTopRightRadius: 4,
                    borderBottomRightRadius: 4,
                    px: 3,
                    ml: -1,
                    zIndex: 1,
                  }}
                  onClick={() => handleIdentifyClick(level, i)}
                  title="Unselect"
                >
                  <Icon icon="minus" />
                </Box>
              )}
              {selectedIndex !== null &&
                (selectedIndexes[level + 1] === null ||
                  selectedIndexes[level + 1] === undefined) && (
                  <Button
                    onClick={() =>
                      handleAddFeeling({
                        name,
                        color: color || baseColor!,
                      })
                    }
                    disabled={isIdentified}
                    sx={{
                      height: "2rem",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: isIdentified ? "#ccc" : color || baseColor,
                      backgroundColor: "#fff",
                      borderColor: isIdentified ? "#ccc" : color || baseColor,
                      borderWidth: 1,
                      borderLeftWidth: 0,
                      borderStyle: "solid",
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0,
                      cursor: isIdentified ? undefined : "pointer",
                      p: 0,
                      px: 3,
                      ml: "-1px",
                    }}
                    title={isIdentified ? "Already added" : "Add this"}
                  >
                    <Icon icon="plus" />
                  </Button>
                )}
            </Flex>
          );
        })}
      </React.Fragment>,
      ...(children || []),
    ];
  };

  return (
    <Box>
      <Helmet>
        <title>Identify</title>
      </Helmet>
      <Text sx={{ mb: 3 }}>
        Identify your feelings by starting with broad feelings and narrowing
        down to more specific ones. You can tap on feelings you have selected to
        remove them. When you're ready move on to explore.
      </Text>
      <Card
        sx={{
          flex: 1,
          m: -1,
          mb: 3,
          p: 2,
        }}
      >
        {!isEmpty(mood?.feelings) && (
          <Flex sx={{ flexWrap: "wrap" }}>
            {mood?.feelings?.map(({ __typename, ...feeling }: any) => {
              const { color } = lookupByName(feeling.feeling);
              return (
                <Feeling
                  key={feeling.id}
                  isSelected={true}
                  allowRemoval={true}
                  animate={false}
                  baseColor={color}
                  onClick={() => deleteFeeling(feeling)}
                  sx={{ m: 1, flex: "0 0 auto", height: "2rem" }}
                >
                  {feeling.feeling}
                </Feeling>
              );
            })}
          </Flex>
        )}
        {!isEmpty(mood?.feelings) && <Divider color="#eee" sx={{ my: 1 }} />}
        <Flex sx={{ alignItems: "center" }} data-el-name="identify-feelings">
          <Button
            sx={{
              height: "2rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              m: 1,
            }}
            onClick={() => {
              setShowAdd(prev => !prev);
              setSelectedIndexes([null, null, null]);
            }}
            data-el-name="identify-add-button"
          >
            {showAdd ? (
              <Icon icon="times" fixedWidth />
            ) : (
              <Icon icon="plus" fixedWidth />
            )}
          </Button>
          <Icon icon="arrow-left" sx={{ mx: 2 }} />{" "}
          {showAdd ? (
            <Text>Never mind</Text>
          ) : (
            <Text>Add {isEmpty(mood?.feelings) ? "a feeling" : "another"}</Text>
          )}
        </Flex>
        {showAdd && (
          <Flex
            sx={{
              flexWrap: "wrap",
              alignItems: "center",
            }}
            data-el-name="feelings-to-add"
          >
            {identifyFeelings(hierarchy)}
          </Flex>
        )}
      </Card>
    </Box>
  );
};

export default Identify;

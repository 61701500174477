import "react-responsive-modal/styles.css";

import React, { useEffect } from "react";

import { Modal as ResponsiveModal } from "react-responsive-modal";
import { useIntercom } from "react-use-intercom";

const Modal = ({ styles, open, ...rest }: any) => {
  const { update } = useIntercom();

  useEffect(() => {
    if (open) {
      update({ hideDefaultLauncher: true });
    } else {
      update({ hideDefaultLauncher: false });
    }
  }, [open, update]);

  return (
    <ResponsiveModal
      open={open}
      styles={{ modal: { borderRadius: 4 }, ...styles }}
      {...rest}
    />
  );
};

export default Modal;

import { A, navigate } from "hookrouter";
import { Box, Container, Flex, NavLink, Link as ThemeLink } from "theme-ui";

import { Helmet } from "react-helmet";
import { Icon } from "../../components";
import { Navigation } from "@commongooddev/commoncode/components";
import React from "react";
import SvgFromUrl from "@commongooddev/commoncode/components/svg-from-url";
import { useApolloClient } from "@apollo/react-hooks";
import { useCommonCode } from "@commongooddev/commoncode/hooks/use-common-code";
import useDimensions from "react-use-dimensions";
import { useIntercom } from "react-use-intercom";

const Layout: React.FC = ({ children, ...rest }) => {
  const client = useApolloClient();
  const [navRef, { height }] = useDimensions();
  const { service } = useCommonCode();
  const { shutdown } = useIntercom();

  const handleLogout = () => {
    client.clearStore();
    shutdown();
    heap.resetIdentity();
    navigate("/");
  };

  if (!service) return null;

  return (
    <>
      <Helmet defaultTitle="Current Mood" titleTemplate="%s | Current Mood" />
      <Navigation onLogout={handleLogout} ref={navRef} />
      <Container
        sx={{
          p: 3,
          px: 3,
          minHeight: () => `calc(100vh - ${height}px)`,
          display: "flex",
          flexDirection: "column",
        }}
        {...rest}
      >
        <Flex sx={{ justifyContent: "space-between" }}>
          <Flex>
            <A href="/">
              <SvgFromUrl
                url={service?.organization?.logos?.logoOnLight?.url}
                width={service?.config?.logoWidth || 16 * 12}
                height={service?.config?.logoHeight || 58}
              />
            </A>
          </Flex>
          <Flex
            as="nav"
            sx={{
              flexGrow: 1,
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <NavLink
              as={A}
              href="/"
              sx={{ display: "inline-flex", alignItems: "center" }}
            >
              <Icon icon="tasks-alt" fixedWidth sx={{ mr: 2 }} />
              Moods
            </NavLink>
            {/* <NavLink as={A} href="/groups">
              <Icon icon="poll-people" fixedWidth sx={{ mt: "1.6px", mr: 2 }} />
              Groups
            </NavLink> */}
            {/* <NavLink as={A} href="/meetings">
              <Icon icon="users-class" fixedWidth sx={{ mr: 2 }} />
              Meetings
            </NavLink> */}
          </Flex>
        </Flex>
        <Flex sx={{ flex: 1, flexDirection: "column", mt: 4 }}>{children}</Flex>
        <Box sx={{ mt: 5, mb: 3, pr: 5, fontSize: 1 }}>
          &copy; 2020 Current Mood.{" "}
          <ThemeLink href="https://currentmood.is/legal/privacy-policy">
            Privacy Policy
          </ThemeLink>{" "}
          |{" "}
          <ThemeLink href="https://currentmood.is/legal/terms-of-use">
            Terms of Use
          </ThemeLink>{" "}
          |{" "}
          <ThemeLink href="https://currentmood.is/legal/terms-of-service">
            Terms of Service
          </ThemeLink>
        </Box>
      </Container>
    </>
  );
};

export default Layout;

import { Box, Card, Flex, Heading } from "theme-ui";
import { FlexSpinner, Icon } from "../../components";
import { useMutation, useQuery } from "@apollo/react-hooks";

import { A } from "hookrouter";
import { Helmet } from "react-helmet";
import ListItem from "../../scales/feelings/list-item";
import React from "react";
import format from "date-fns/format";
import formatDistanceToNowStrict from "date-fns/formatDistanceToNowStrict";
import { gql } from "apollo-boost";
import isThisYear from "date-fns/isThisYear";
import isToday from "date-fns/isToday";
import isYesterday from "date-fns/isYesterday";
import { navigate } from "hookrouter";
import parse from "date-fns/parseISO";
import { useIntercom } from "react-use-intercom";
import { useLoginRequired } from "@commongooddev/commoncode/hooks/use-login-required";

export const MoodsQuery = gql`
  query MoodsQuery {
    moods(order_by: { created_at: desc }) {
      id
      scale
      mood_at
      thoughts
      feelings(order_by: { created_at: asc }) {
        id
        feeling
        strength
        thoughts
      }
    }
  }
`;

const InsertMoodMutation = gql`
  mutation InsertMoodMutation($scale: scale_enum = feelings) {
    insert_moods_one(object: { scale: $scale }) {
      id
      scale
    }
  }
`;

const Moods = () => {
  useLoginRequired();
  const { data, loading, error } = useQuery(MoodsQuery);
  const [createMood, { loading: newMoodLoading }] = useMutation(
    InsertMoodMutation
  );

  const { trackEvent } = useIntercom();

  const handleNewMood = async () => {
    const { data } = await createMood({
      refetchQueries: [{ query: MoodsQuery }],
      awaitRefetchQueries: true,
    });
    trackEvent("Created Mood");
    navigate(`/moods/${data?.insert_moods_one?.id}`);
  };

  if (loading) return <FlexSpinner />;
  if (error) return <Flex>{error.message}</Flex>;

  return (
    <Box>
      <Helmet>
        <title>Moods</title>
      </Helmet>
      <Heading sx={{ mb: 3 }}>Moods</Heading>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: ["1fr", "repeat(2, 1fr)", "repeat(3, 1fr)"],
          gap: 3,
        }}
      >
        <Card
          sx={{
            p: 2,
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            minHeight: "5rem",
            "&:hover": {
              backgroundColor: "#FCFAEE",
            },
          }}
          onClick={() => !newMoodLoading && handleNewMood()}
          data-el-name="new-mood-button"
        >
          {newMoodLoading ? (
            <FlexSpinner />
          ) : (
            <>
              <Icon icon="plus" sx={{ mr: 2 }} /> New Mood
            </>
          )}
        </Card>
        {data.moods.map((mood: any) => (
          <Card
            key={mood.id}
            sx={{ p: 2, cursor: "pointer", height: "auto" }}
            onClick={() => navigate(`/moods/${mood.id}`)}
            data-el-name="mood-button"
          >
            <Flex
              sx={{
                flexWrap: "wrap",
                justifyContent: "space-between",
                alignItems: "stretch",
                backgroundColor: "primary",
                color: "white",
                fontSize: 0,
                whiteSpace: "nowrap",
                borderRadius: "4px 4px 0 0",
                p: 1,
                mt: -2,
                ml: -2,
                mr: -2,
                mb: 3,
              }}
            >
              <Box
                sx={{
                  display: "inline-flex",
                  alignItems: "center",
                  px: 1,
                }}
              >
                {formatMoodDate(mood.mood_at)}
              </Box>
              <Box
                sx={{
                  display: "inline-flex",
                  alignItems: "center",
                  px: 1,
                  "> a": { textDecoration: "none", color: "white" },
                }}
              >
                <A href={`/moods/${mood.id}`}>&rarr;</A>
              </Box>
            </Flex>
            <ListItem mood={mood} />
          </Card>
        ))}
      </Box>
    </Box>
  );
};

const formatMoodDate = (dateString: string) => {
  const moodAt = parse(dateString);
  const relative = formatDistanceToNowStrict(moodAt, { addSuffix: true });
  let dateRef;
  if (isToday(moodAt)) {
    dateRef = "Today";
  } else if (isYesterday(moodAt)) {
    dateRef = "Yesterday";
  } else if (isThisYear(moodAt)) {
    dateRef = format(moodAt, "MMM d");
  } else {
    dateRef = format(moodAt, "PP");
  }
  return `${dateRef} at ${format(moodAt, "p")} (${relative})`;
};

export default Moods;

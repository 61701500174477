import hierarchy from "./feelings.json";
import { useMemo } from "react";

const flattenFeelings = (feelings: any[], baseColor?: string) => {
  const flattened: any[] = [];
  feelings.forEach(({ name, color, children }) => {
    flattened.push({ name, color: color || baseColor || "#ccc" });
    if (children) {
      flattened.push(...flattenFeelings(children, color || baseColor));
    }
  });
  return flattened;
};

const useFeelings = () => {
  const flat = useMemo(() => flattenFeelings(hierarchy), []);

  const lookupByName = (nameToFind: string) =>
    flat.find(({ name }) => name === nameToFind);

  return { hierarchy, flat, lookupByName };
};

export default useFeelings;

import { Box, Button, Flex } from "theme-ui";
import { Icon, Modal, MoodAt } from "../../components";
import React, { useEffect, useState } from "react";
import { navigate, usePath, useRoutes } from "hookrouter";

import Explore from "./explore";
import Identify from "./identify";
import { MoodQuery } from "../../pages/mood/mood";
import Reflect from "./reflect";
import filter from "lodash/filter";
// import Share from "../../pages/mood/share";
import { gql } from "apollo-boost";
import parseISO from "date-fns/parseISO";
import { useLoginRequired } from "@commongooddev/commoncode/hooks/use-login-required";
import { useMutation } from "@apollo/react-hooks";

interface Props {
  mood: any;
  groups: any;
  updateMood: any;
  onSavingChange: (newSavingState: boolean) => void;
}

const InsertFeelingMutation = gql`
  mutation InsertFeelingMutation(
    $mood_id: uuid!
    $feeling: String!
    $strength: Int
    $thoughts: String
  ) {
    insert_feelings_one(
      object: {
        mood_id: $mood_id
        feeling: $feeling
        strength: $strength
        thoughts: $thoughts
      }
    ) {
      id
      feeling
      strength
      thoughts
      mood_id
    }
  }
`;

const UpdateFeelingMutation = gql`
  mutation UpdateFeelingMutation(
    $id: uuid!
    $feeling: String
    $strength: Int
    $thoughts: String
  ) {
    update_feelings_by_pk(
      pk_columns: { id: $id }
      _set: { feeling: $feeling, strength: $strength, thoughts: $thoughts }
    ) {
      id
      mood_id
      feeling
      strength
      thoughts
    }
  }
`;

const DeleteFeelingMutation = gql`
  mutation DeleteFeelingMutation($id: uuid!) {
    delete_feelings_by_pk(id: $id) {
      id
    }
  }
`;

const routes = {
  "/": () => (props: any) => <Identify {...props} />,
  "/explore": () => (props: any) => <Explore {...props} />,
  "/reflect": () => (props: any) => <Reflect {...props} />,
  // "/share": () => (props: any) => <Share {...props} mood={mood} groups={groups} />,
};

const InputTab: React.FC<{
  href: string;
  iconName: string;
  tabName: string;
  [prop: string]: any;
}> = ({ href, iconName, tabName, ...rest }) => {
  const currentPath = usePath();
  const isCurrentPath = currentPath === href;
  return (
    <Button
      variant={isCurrentPath ? "primary" : "link"}
      sx={{ py: 1, px: 2 }}
      onClick={() => navigate(href)}
      data-el-name={`${tabName.toLowerCase()}-tab`}
      {...rest}
    >
      <Icon icon={iconName} size="sm" sx={{ mr: 1 }} /> {tabName}
    </Button>
  );
};

const Input: React.FC<Props> = ({
  mood,
  // groups,
  updateMood,
  onSavingChange,
}) => {
  useLoginRequired();
  const currentRoute = useRoutes(routes);
  const [feelingToDelete, setFeelingToDelete] = useState(null);

  const [insertFeeling, { loading: insertFeelingLoading }] = useMutation(
    InsertFeelingMutation,
    {
      update: (proxy, { data: { insert_feelings_one } }) => {
        const data: any = proxy.readQuery({
          query: MoodQuery,
          variables: { id: mood.id },
        });
        proxy.writeQuery({
          query: MoodQuery,
          variables: { id: mood.id },
          data: {
            ...data,
            moods_by_pk: {
              ...data.moods_by_pk,
              feelings: [...data.moods_by_pk.feelings, insert_feelings_one],
            },
          },
        });
      },
    }
  );
  const [updateFeeling, { loading: updateFeelingLoading }] = useMutation(
    UpdateFeelingMutation
  );
  const [deleteFeeling, { loading: deleteFeelingLoading }] = useMutation(
    DeleteFeelingMutation,
    {
      update: (proxy, { data: { delete_feelings_by_pk } }) => {
        const data: any = proxy.readQuery({
          query: MoodQuery,
          variables: { id: mood.id },
        });
        const feelings = data.moods_by_pk.feelings;
        proxy.writeQuery({
          query: MoodQuery,
          variables: { id: mood.id },
          data: {
            ...data,
            moods_by_pk: {
              ...data.moods_by_pk,
              feelings: filter(
                feelings,
                ({ id }) => id !== delete_feelings_by_pk.id
              ),
            },
          },
        });
      },
    }
  );

  const deleteFeelingWithoutConfirmation = async (
    id: string | null = feelingToDelete
  ) => {
    await deleteFeeling({
      variables: { id },
      optimisticResponse: {
        delete_feelings_by_pk: {
          __typename: "feelings",
          id,
        },
      },
    });
    setFeelingToDelete(null);
  };

  const deleteFeelingWithConfirmation = async ({
    id,
    strength,
    thoughts,
  }: any) => {
    if (!strength && !thoughts) {
      deleteFeelingWithoutConfirmation(id);
    } else {
      setFeelingToDelete(id);
    }
  };

  useEffect(() => {
    const isSaving =
      insertFeelingLoading || updateFeelingLoading || deleteFeelingLoading;
    onSavingChange(isSaving);
  }, [
    onSavingChange,
    insertFeelingLoading,
    updateFeelingLoading,
    deleteFeelingLoading,
  ]);

  const handleMoodAtSave = async (newMoodAt?: string) => {
    if (!newMoodAt) return;
    await updateMood({
      variables: {
        mood_at: newMoodAt,
      },
    });
  };

  return (
    <>
      <Flex
        sx={{
          flexDirection: ["column", null, "row"],
          alignItems: ["flex-start", null, "center"],
          mb: 4,
        }}
      >
        <Box
          as="nav"
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(4, max-content)",
            mb: [5, null, 0],
            mr: 3,
            gap: 1,
          }}
        >
          <InputTab
            href={`/moods/${mood.id}`}
            iconName="network-wired"
            tabName="Identify"
          />
          <InputTab
            href={`/moods/${mood.id}/explore`}
            iconName="line-columns"
            tabName="Explore"
          />
          <InputTab
            href={`/moods/${mood.id}/reflect`}
            iconName="file-signature"
            tabName="Reflect"
          />
          {/* <InputTab
            href={`/moods/${mood.id}/share`}
            iconName="share"
            tabName="Share"
          /> */}
        </Box>
        <MoodAt moodAt={parseISO(mood.mood_at)} onSave={handleMoodAtSave} />
        <Modal
          open={!!feelingToDelete}
          onClose={() => setFeelingToDelete(null)}
          center
        >
          <Flex
            sx={{
              p: 3,
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <p>
              Are you sure you want to delete this feeling? There is no undo.
            </p>
            <Box
              sx={{
                display: "grid",
                gap: 2,
                gridTemplateColumns: ["1fr", "1fr 1fr"],
              }}
            >
              <Button
                onClick={() => setFeelingToDelete(null)}
                variant="secondary"
                sx={{
                  display: "inline-flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Icon icon="times" sx={{ mr: 2 }} />
                No way!
              </Button>
              <Button
                onClick={() => deleteFeelingWithoutConfirmation()}
                variant="danger"
                sx={{
                  backgroundColor: "danger",
                  display: "inline-flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Icon icon="trash" sx={{ mr: 2 }} />
                Yes, delete this feeling
              </Button>
            </Box>
          </Flex>
        </Modal>
      </Flex>
      {currentRoute({
        mood,
        updateMood,
        insertFeeling,
        updateFeeling,
        deleteFeeling: deleteFeelingWithConfirmation,
      })}
    </>
  );
};

export default Input;

import "react-datetime/css/react-datetime.css";

import ApolloClient, { Operation } from "apollo-boost";
import { Box, ThemeProvider } from "theme-ui";
import { Group, Groups, Mood, Moods } from "./pages";
import { HookRouter, usePath, useRoutes } from "hookrouter";
import React, { useEffect } from "react";

import { ApolloProvider } from "@apollo/react-hooks";
import ForgotPasswordForm from "@commongooddev/commoncode/components/forgot-password-form";
import { Layout } from "./components";
import LoginForm from "@commongooddev/commoncode/components/login-form";
import ResetPasswordForm from "@commongooddev/commoncode/components/reset-password-form";
import SignupForm from "@commongooddev/commoncode/components/signup-form";
import { getAccessTokenDirectly } from "@commongooddev/commoncode/hooks/use-common-code/auth";
import { useCommonCode } from "@commongooddev/commoncode/hooks/use-common-code";
import { useIntercom } from "react-use-intercom";
import { useResolvedTheme } from "@commongooddev/commoncode/hooks/use-resolved-theme";

const client = new ApolloClient({
  uri:
    process.env.REACT_APP_GRAPHQL_ENDPOINT_URL ||
    "http://gql.currentmood.test/v1/graphql",
  request: (operation: Operation) => {
    const accessToken = getAccessTokenDirectly();
    if (accessToken) {
      operation.setContext({
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    }
  },
});

const publicRoutes: HookRouter.RouteObject = {
  "/login": () => <LoginForm />,
  "/signup": () => <SignupForm />,
  "/forgot-password": () => <ForgotPasswordForm />,
  "/reset/:token": ({ token }) => (
    <ResetPasswordForm passwordResetToken={token} />
  ),
};

const routes: HookRouter.RouteObject = {
  "/moods/:id*": ({ id }) => <Mood moodId={id} />,
  "/groups": () => <Groups />,
  "/groups/:id": ({ id }) => <Group groupId={id} />,
  "/": () => <Moods />,
};

const App = () => {
  usePath();
  const publicRoute = useRoutes(publicRoutes);
  const currentRoute = useRoutes(routes);

  const theme = useResolvedTheme({
    links: {
      nav: {
        p: 2,
      },
    },
    styles: { root: { backgroundColor: "#fff" } },
  });

  const { initializing, currentUser, currentUserLoading } = useCommonCode();
  const { update } = useIntercom();

  useEffect(() => {
    if (initializing || currentUserLoading) return;

    if (currentUser) {
      update({ userId: currentUser.id, userHash: currentUser.intercomHash });
      heap.identify(currentUser.id);
      heap.addUserProperties({ email: currentUser.email });
    } else {
      update();
    }
  }, [initializing, currentUserLoading, currentUser, update]);

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        {publicRoute && <Box sx={{ pt: 5 }}>{publicRoute}</Box>}
        {!publicRoute && <Layout>{currentRoute || "Not found"}</Layout>}
      </ThemeProvider>
    </ApolloProvider>
  );
};

export default App;

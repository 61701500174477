import { Box, Flex, Text } from "theme-ui";

import Feeling from "./feeling";
import { Helmet } from "react-helmet";
import React from "react";
import find from "lodash/find";
import useFeelings from "./use-feelings";
import { useIntercom } from "react-use-intercom";

const Explore: React.FC<any> = ({ mood, insertFeeling, deleteFeeling }) => {
  const { flat } = useFeelings();

  const { trackEvent } = useIntercom();

  const isSelected = (name: string) => !!getFeelingByName(name);

  const getFeelingByName = (name: string) =>
    find(mood.feelings, { feeling: name });

  const toggleSelection = (name: string) => {
    if (isSelected(name)) {
      const feeling = getFeelingByName(name);
      deleteFeeling(feeling);
    } else {
      insertFeeling({
        variables: {
          mood_id: mood.id,
          feeling: name,
        },
        optimisticResponse: {
          insert_feelings_one: {
            __typename: "feelings",
            id: null,
            strength: null,
            thoughts: null,
            mood_id: mood.id,
            feeling: name,
          },
        },
      });
      trackEvent("Added Feeling", { From: "Explore" });
    }
  };

  return (
    <Box>
      <Helmet>
        <title>Explore</title>
      </Helmet>
      <Text sx={{ mb: 3 }}>
        Browse the entire list of feelings words to see if any of them stick out
        to you. Tap to select and tap again to deselect. When you're ready, move
        on to reflect.
      </Text>
      <Flex sx={{ my: 2, flexWrap: "wrap", flex: 1 }}>
        {flat.map(({ name, color }) => (
          <Feeling
            key={name}
            baseColor={color}
            onClick={() => toggleSelection(name)}
            isSelected={isSelected(name)}
            allowRemoval={true}
          >
            {name}
          </Feeling>
        ))}
      </Flex>
    </Box>
  );
};

export default Explore;

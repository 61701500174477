import {
  Box,
  Button,
  Card,
  Heading,
  Input,
  SystemStyleObject,
  Text,
} from "theme-ui";
import { Icon, MinimalLink } from "../../components";
import React, { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useMutation, useQuery } from "@apollo/react-hooks";

import { gql } from "apollo-boost";
import { useLoginRequired } from "@commongooddev/commoncode/hooks/use-login-required";

const GroupFragment = gql`
  fragment GroupFragment on groups {
    id
    name
    group_users_aggregate {
      aggregate {
        count
      }
    }
    group_moods_as_group_user_aggregate {
      aggregate {
        count
      }
    }
  }
`;

export const GroupsQuery = gql`
  query GroupsQuery {
    groups {
      ...GroupFragment
    }
  }
  ${GroupFragment}
`;

const InsertGroupMutation = gql`
  mutation InsertGroupMutation($name: String = "") {
    insert_groups_one(object: { name: $name, group_users: { data: {} } }) {
      ...GroupFragment
    }
  }
  ${GroupFragment}
`;

type FormValues = {
  name: string;
};

const GroupCard: React.FC<{ sx?: SystemStyleObject; [prop: string]: any }> = ({
  sx,
  ...rest
}) => (
  <Card
    sx={{
      p: 4,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      ...sx,
    }}
    {...rest}
  />
);

const Groups = () => {
  useLoginRequired();
  const [showNewForm, setShowNewForm] = useState(false);
  const { register, handleSubmit, errors } = useForm<FormValues>();
  const { data, loading } = useQuery(GroupsQuery, {
    fetchPolicy: "cache-and-network",
  });
  const [insertGroup] = useMutation(InsertGroupMutation);

  const onSubmit: SubmitHandler<FormValues> = async ({ name }) => {
    await insertGroup({
      variables: { name },
      refetchQueries: [{ query: GroupsQuery }],
      awaitRefetchQueries: true,
    });
    setShowNewForm(false);
  };

  if (loading) return null;

  return (
    <Box>
      <Heading sx={{ mb: 3 }}>Groups</Heading>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: ["1fr", "repeat(2, 1fr)", "repeat(3, 1fr)"],
          gap: 3,
        }}
      >
        {data.groups.map(
          ({
            id,
            name,
            group_users_aggregate: {
              aggregate: { count: usersCount },
            },
            group_moods_as_group_user_aggregate: {
              aggregate: { count: moodsCount },
            },
          }: any) => (
            <MinimalLink key={id} href={`/groups/${id}`}>
              <GroupCard>
                <Heading as="h3" sx={{ m: 0, mb: 3 }}>
                  {name}
                </Heading>
                <Box
                  sx={{
                    display: "grid",
                    gap: 3,
                    gridTemplateColumns: "1fr 1fr",
                    fontSize: 1,
                    color: "#aaa",
                  }}
                >
                  <Box>
                    <Icon icon="user-friends" sx={{ mr: 2 }} />
                    {usersCount || 0}
                  </Box>
                  <Box>
                    <Icon icon="poll-people" sx={{ mr: 2 }} />
                    {moodsCount || 0}
                  </Box>
                </Box>
              </GroupCard>
            </MinimalLink>
          )
        )}
        {!showNewForm && (
          <GroupCard
            sx={{
              "&:hover": {
                backgroundColor: "#FCFAEE",
              },
              cursor: "pointer",
            }}
            onClick={() => setShowNewForm(true)}
          >
            <Box>
              <Icon icon="plus" sx={{ mr: 2 }} />
              New Group
            </Box>
          </GroupCard>
        )}
        {showNewForm && (
          <GroupCard>
            <form onSubmit={handleSubmit(onSubmit)}>
              {errors.name?.type === "required" && (
                <Text color="danger" sx={{ mb: 2, fontSize: 0 }}>
                  Please name your group.
                </Text>
              )}
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "2fr 1fr",
                  gap: 2,
                  alignItems: "flex-end",
                  fontSize: 1,
                }}
              >
                <Input
                  ref={register({ required: true })}
                  placeholder="My Group Name"
                  name="name"
                />
                <Button type="submit">Save</Button>
              </Box>
            </form>
          </GroupCard>
        )}
      </Box>
    </Box>
  );
};

export default Groups;

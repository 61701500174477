import * as Feelings from "../../scales/feelings";

import { Box, Button, Flex } from "theme-ui";
import { ButtonLink, Icon, Modal } from "../../components";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";

import { Helmet } from "react-helmet";
import { MoodsQuery } from "../moods/moods";
import format from "date-fns/format";
import { gql } from "apollo-boost";
import { navigate } from "hookrouter";
import parseISO from "date-fns/parseISO";
import { useIntercom } from "react-use-intercom";
import { useLoginRequired } from "@commongooddev/commoncode/hooks/use-login-required";

const scaleToInput: Record<string, any> = {
  feelings: Feelings.Input,
};

export const MoodQuery = gql`
  query MoodQuery($id: uuid!) {
    moods_by_pk(id: $id) {
      id
      scale
      mood_at
      thoughts
      feelings(order_by: { created_at: asc }) {
        id
        feeling
        strength
        thoughts
      }
    }
    groups {
      id
      name
      group_moods(where: { mood_id: { _eq: $id } }) {
        id
      }
    }
  }
`;

const UpdateMoodMutation = gql`
  mutation UpdateMoodMutation(
    $id: uuid!
    $thoughts: String
    $mood_at: timestamptz
  ) {
    update_moods_by_pk(
      pk_columns: { id: $id }
      _set: { thoughts: $thoughts, mood_at: $mood_at }
    ) {
      id
      mood_at
      scale
      thoughts
    }
  }
`;

const DeleteMoodMutation = gql`
  mutation DeleteMoodMutation($id: uuid!) {
    delete_moods_by_pk(id: $id) {
      id
    }
  }
`;

interface Props {
  moodId: string;
}

const Mood: React.FC<Props> = ({ moodId }) => {
  useLoginRequired();

  const { trackEvent } = useIntercom();

  const [isSaving, setIsSaving] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { data, loading } = useQuery(MoodQuery, {
    variables: { id: moodId },
  });

  const [deleteMood] = useMutation(DeleteMoodMutation);

  const [updateMood, { loading: updateMoodLoading }] = useMutation(
    UpdateMoodMutation,
    {
      variables: { id: moodId },
    }
  );

  useEffect(() => {
    handleDelayedSavingStateChange(updateMoodLoading);
  }, [updateMoodLoading]);

  const handleDelayedSavingStateChange = (newState: boolean) => {
    if (newState) {
      setIsSaving(newState);
    } else {
      setTimeout(() => setIsSaving(newState), 500);
    }
  };

  const handleDeleteMood = async () => {
    await deleteMood({
      variables: { id: moodId },
      refetchQueries: [{ query: MoodsQuery }],
      awaitRefetchQueries: true,
    });
    trackEvent("Deleted Mood");
    navigate("/");
  };

  if (loading) return null;

  const mood = data.moods_by_pk;
  const groups = data.groups;
  const Input = scaleToInput[mood.scale];

  return (
    <Box>
      <Helmet
        defaultTitle={`Mood as of ${format(
          parseISO(mood.mood_at),
          "PPP 'at' p"
        )} | Current Mood`}
        titleTemplate={` %s | ${format(
          parseISO(mood.mood_at),
          "PPP, p"
        )} | Current Mood`}
      />
      <Input
        mood={mood}
        groups={groups}
        updateMood={updateMood}
        onSavingChange={(newState: boolean) =>
          handleDelayedSavingStateChange(newState)
        }
      />
      <Flex
        sx={{ justifyContent: "space-between", alignItems: "center", mt: 5 }}
      >
        <ButtonLink href="/" sx={{ p: 1, px: 2 }} data-el-name="back-button">
          <Icon icon="arrow-left" sx={{ mr: 2 }} />
          Back
        </ButtonLink>
        <Flex>
          <Flex
            sx={{
              color: isSaving ? "warning" : "success",
              alignItems: "center",
              justifyContent: "center",
              mr: 3,
            }}
          >
            {isSaving ? (
              <Icon icon="spinner" className="fa-spin" sx={{ mr: 1 }} />
            ) : (
              <Icon icon="check-circle" sx={{ mr: 1 }} />
            )}
            {isSaving ? "SAVING" : "SAVED"}
          </Flex>
          <Button
            onClick={() => setShowDeleteModal(true)}
            variant="secondary"
            sx={{
              "&:hover": { backgroundColor: "danger" },
              display: "inline-flex",
              justifyContent: "center",
              alignItems: "center",
              p: 1,
              px: 2,
            }}
            data-el-name="delete-button"
          >
            <Icon icon="trash" sx={{ mr: 2 }} />
            Delete
          </Button>
        </Flex>
        <Modal
          open={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          center
        >
          <Flex
            sx={{
              p: 3,
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <p>Are you sure you want to delete this mood? There is no undo.</p>
            <Box
              sx={{
                display: "grid",
                gap: 2,
                gridTemplateColumns: ["1fr", "1fr 1fr"],
              }}
            >
              <Button
                onClick={() => setShowDeleteModal(false)}
                variant="secondary"
                sx={{
                  display: "inline-flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                data-el-name="dont-delete-mood-button"
              >
                <Icon icon="times" sx={{ mr: 2 }} />
                No way!
              </Button>
              <Button
                onClick={handleDeleteMood}
                variant="danger"
                sx={{
                  backgroundColor: "danger",
                  display: "inline-flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                data-el-name="confirm-delete-mood-button"
              >
                <Icon icon="trash" sx={{ mr: 2 }} />
                Yes, delete this mood
              </Button>
            </Box>
          </Flex>
        </Modal>
      </Flex>
    </Box>
  );
};

export default Mood;

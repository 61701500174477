import { Box, Button, Card, SystemStyleObject } from "theme-ui";
import React, { useState } from "react";

import DateTime from "react-datetime";
import { Icon } from "../../components";
import Popover from "react-tiny-popover";
import format from "date-fns/format";
import formatISO from "date-fns/formatISO";
import { useIntercom } from "react-use-intercom";

interface Props {
  moodAt: Date;
  onSave: (newMoodAt?: string) => void | Promise<void>;
  sx?: SystemStyleObject;
}

const MoodAt: React.FC<Props> = ({ moodAt, onSave, sx }) => {
  const [showPicker, setShowPicker] = useState(false);
  const [newMoodAt, setNewMoodAt] = useState<string>();
  const { trackEvent } = useIntercom();

  const handleMoodAtChange = (changedMoodAt: moment.Moment | string) => {
    if (typeof changedMoodAt === "string") return;
    setNewMoodAt(changedMoodAt.toISOString());
  };

  const handleSave = async () => {
    trackEvent("Updated Mood At", { Was: formatISO(moodAt), Is: newMoodAt });
    await onSave(newMoodAt);
    setShowPicker(false);
  };

  return (
    <Popover
      isOpen={showPicker}
      position="bottom"
      align="start"
      content={
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
          }}
        >
          <DateTime
            defaultValue={moodAt}
            input={false}
            closeOnSelect={false}
            disableOnClickOutside={true}
            onChange={handleMoodAtChange}
          />
          <Button
            onClick={handleSave}
            sx={{ borderRadius: "0 0 4px 4px" }}
            data-el-name="save-mood-time-button"
          >
            Save
          </Button>
        </Card>
      }
    >
      <Box
        onClick={() => setShowPicker(prev => !prev)}
        sx={{
          display: "inline-flex",
          cursor: "pointer",
          fontSize: 2,
          fontWeight: "bold",
          alignItems: "center",
          ...sx,
        }}
        data-el-name="edit-mood-time-button"
      >
        <Icon icon={showPicker ? "times-circle" : "clock"} sx={{ mr: 2 }} />
        {format(moodAt, "PP 'at' p")}
      </Box>
    </Popover>
  );
};

export default MoodAt;

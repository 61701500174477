import { Flex, SystemStyleObject } from "theme-ui";
import React, { useState } from "react";

import { Icon } from "../../components";

interface Props {
  isSelected?: boolean;
  allowRemoval?: boolean;
  animate?: boolean;
  baseColor?: string;
  textColor?: string;
  bgColor?: string;
  borderColor?: string;
  sx?: SystemStyleObject | any;
  onClick?: () => void;
}

const Feeling: React.FC<Props> = ({
  isSelected,
  allowRemoval = false,
  animate = true,
  baseColor,
  bgColor,
  borderColor,
  onClick,
  sx,
  children,
  ...rest
}) => {
  const [mouseIsOver, setMouseIsOver] = useState(false);
  const textColor = isSelected ? "white" : baseColor;
  const backgroundColor = isSelected ? baseColor : "white";

  return (
    <Flex
      sx={{
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: bgColor || backgroundColor || baseColor,
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: borderColor || baseColor,
        color: textColor,
        m: 1,
        flexGrow: 1,
        py: 1,
        px: 2,
        textAlign: "center",
        cursor: onClick && "pointer",
        height: "min-content",
        userSelect: "none",
        borderRadius: 4,
        position: "relative",
        ...sx,
      }}
      data-el-name="feeling"
      data-feeling-name={((children as string) || "feeling-name").toLowerCase()}
      onMouseOver={() => allowRemoval && setMouseIsOver(true)}
      onMouseOut={() => allowRemoval && setMouseIsOver(false)}
      {...rest}
      onClick={onClick}
    >
      {allowRemoval && isSelected && mouseIsOver && (
        <Icon
          icon="minus"
          sx={{
            border: "1px solid #000",
            borderColor: bgColor || backgroundColor || baseColor,
            borderRadius: "50%",
            backgroundColor: "white",
            color: bgColor || backgroundColor || baseColor,
            width: 16,
            height: 16,
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            position: "absolute",
            right: "-6px",
            top: "-6px",
          }}
          size="xs"
        />
      )}
      {children}
    </Flex>
  );
};

export default Feeling;
